<template>
    <!-- 个人中心路由 -->
    <div class="center_box">
        <!-- 上半部分 -->
        <center-user></center-user>
        <!-- 下半部分 -->
        <div class="container">
            <el-row :gutter="20">
                <!-- 导航栏列 -->
                <el-col :span="5">
                    <div class="center-menu-box">
                        <el-menu
                            :default-active="activeMenu"
                            active-text-color="#E6A23C"
                            @open="handleOpen"
                            @close="handleClose"
                            unique-opened
                            router>
                            <!-- 账户管理 -->
                            <el-submenu index="1">
                                <!-- 标题 -->
                                <template slot="title">
                                    <img :src="require('@/assets/images/center/@1x/icon_black_personalcenter.png')" alt="">
                                    <span style="margin-left:10px">账户管理</span>
                                </template>
                                <!-- 子导航栏 -->
                                <el-menu-item index="/center/basic">基本资料</el-menu-item>
                                <el-menu-item index="/center/pwd">修改密码</el-menu-item>
                                <el-menu-item index="/center/identity">实名认证</el-menu-item>
                                <el-menu-item index="/center/campus">校园认证</el-menu-item>
                            </el-submenu>
                            <!-- 我的资源 -->
                            <el-submenu index="2">
                                <!-- 标题 -->
                                <template slot="title">
                                    <img :src="require('@/assets/images/center/@1x/icon_black_pesource.png')" alt="">
                                    <span style="margin-left:10px">我的身份</span>
                                </template>
                                <!-- 子导航栏 -->
                                <el-menu-item
                                    index="/center/lensman/info"
                                    v-if="user_type == 21 || user_type == 22">
                                    摄影师
                                </el-menu-item>
                                <el-menu-item
                                    index="/center/dresser/info"
                                    v-if="user_type == 21 || user_type == 22">
                                    化妆师
                                </el-menu-item>
                                <el-menu-item
                                    index="/center/camera">
                                    服饰管理
                                </el-menu-item>
                                <el-menu-item
                                    index="/center/field"
                                    v-if="user_type == 23">
                                    场地管理
                                </el-menu-item>
                            </el-submenu>
                            <!-- 原创作者 -->
                            <el-submenu index="3">
                                <!-- 标题 -->
                                <template slot="title">
                                    <img :src="require('@/assets/images/center/@1x/icon_black_wallet.png')" alt="">
                                    <span style="margin-left:10px">原创作者</span>
                                </template>
                                <!-- 子导航栏 -->
                                <el-menu-item index="/center/original/data">基本资料</el-menu-item>
                                <!-- 子导航栏 -->
                                <el-menu-item index="/center/original/list">上传模板</el-menu-item>
                            </el-submenu>
                            <!-- 我的订单 -->
                            <el-submenu index="4">
                                <!-- 标题 -->
                                <template slot="title">
                                    <img :src="require('@/assets/images/center/@1x/icon_black_oder.png')" alt="">
                                    <span style="margin-left:10px">我的订单</span>
                                </template>
                                <!-- 子导航栏 -->
                                <!-- v-if="user_type == 24 || user_type == 1" -->
                                <el-menu-item
                                    index="/center/ownPurchase">
                                    购买订单
                                </el-menu-item>
                                <el-menu-item
                                    index="/center/ownlease">
                                    租赁订单
                                </el-menu-item>
                                <!-- v-if="user_type == 21 || user_type == 22 || user_type == 1" -->
                                <el-menu-item
                                    index="/center/ownphotography">
                                    摄影订单
                                </el-menu-item>
                                <el-menu-item
                                    index="/center/site">
                                    场地订单
                                </el-menu-item>
                                <el-menu-item
                                    index="/center/ownService">
                                    服务订单
                                </el-menu-item>
                                <el-menu-item
                                    index="/center/ownMetope">
                                    制作订单
                                </el-menu-item>


                            </el-submenu>
                            <!-- 接单 -->
                            <el-submenu index="5">
                                <!-- 标题 -->
                                <template slot="title">
                                    <img :src="require('@/assets/images/center/@1x/icon_black_oder.png')" alt="">
                                    <span style="margin-left:10px">接单</span>
                                </template>
                                <el-menu-item
                                    index="/center/purchase">
                                  购买订单
                                </el-menu-item>
                                <el-menu-item
                                    index="/center/lease">
                                    租赁订单
                                </el-menu-item>
                                <!-- v-if="user_type == 21 || user_type == 22 || user_type == 1" -->
                                <el-menu-item
                                    index="/center/photography"
                                    v-if="user_type == 21 || user_type == 22">
                                    摄影订单
                                </el-menu-item>
                                <el-menu-item
                                    index="/center/service">
                                    服务订单
                                </el-menu-item>
								<el-menu-item
								    index="/center/store">
								    制作订单
								</el-menu-item>
                            </el-submenu>
                            <!-- 我的钱包 -->
                            <el-submenu index="6">
                                <!-- 标题 -->
                                <template slot="title">
                                    <img :src="require('@/assets/images/center/@1x/icon_black_wallet.png')" alt="">
                                    <span style="margin-left:10px">我的钱包</span>
                                </template>
                                <!-- 子导航栏 -->
                                <el-menu-item index="/center/wallet">账单明细</el-menu-item>
                            </el-submenu>
                        </el-menu>
                    </div>
                </el-col>
                <el-col :span="19">
                    <div class="center_right_box">
                        <router-view></router-view>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    data(){
        return {
            activeMenu:"/center/basic",
        }
    },
    computed: mapState({
        user_type: state => state.user.user_type,
        basic_data: state => state.user.basic_data
    }),
    components: {
        centerUser: () => import("@/components/centerUser"),
        centerUserBalance: () => import("@/components/centerUserBalance"),
    },
    methods: {
        ...mapMutations([
            "setBasicData"
        ]),
        ...mapActions([
            "getBasicData"
        ]),
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        },
        // 高亮菜单写入
        handleSelect(menu){
            this.activeMenu = menu;
        },
    },
    mounted() {
        // 加载页面时，更新导航栏高亮位置
        this.handleSelect(this.$route.path);
    },
    beforeRouteEnter (to, from, next) {
        // ...
        next(vm => {
            vm.getBasicData();
        })
    },
    beforeRouteLeave (to, from, next) {
        this.setBasicData({})
        next();
    },
    watch:{
        $route(){
            // 监听路由跳转，更新导航栏高亮位置
            this.handleSelect(this.$route.path);
        },
    }
}
</script>

<style scoped>
/* 盒子 */
.center_box{
    /* padding-bottom: 80px; */
    background: #e4e4e4;
}


/* 导航栏 */
.center-menu-box{
    padding: 20px;
    background-color: #fff;
}
.center-menu-box>>>.el-menu{
    border-color: #fff;
}
.center-menu-box>>>.el-submenu__title{
    height: 30px;
    line-height: 30px;
    margin: 10px 0;
    border-radius: 30px;
    background-color: #8d9afc;
    color: #fff;
}
.center-menu-box>>>.el-submenu__title>i{
    color: #fff;
}

/* 右侧内容区域 */
.center_right_box{
    width: 100%;
    background-color: #fff;
    padding: 30px 40px;
}
</style>
